import React from "react";
import { useSelector } from "react-redux";
import Two from "./Two";
import One from "./One";
import "../styles/knowledgeAgent.css";
import SidebarOne from "./SidebarOne";
import SidebarThree from "./SidebarThree";

function KnowledgeAgentWorkflow() {
  const engagementTemplate = useSelector(
    (state) =>
      state.flipcard.data?.flipCard?.engagement?.engagementData
        ?.engagementTemplate
  );

  const injectionStyle = useSelector(
    (state) => state.flipcard.data.config.injectionStyle
  );

  if (engagementTemplate === "two") {
    return <Two />;
  } else if (engagementTemplate === "three") {
    return <SidebarThree />;
  } else {
    if (injectionStyle === "sidePanel") {
      return <SidebarOne />;
    }
    return <One />;
  }
}

export default KnowledgeAgentWorkflow;
