import React from "react";
import { useSelector } from "react-redux";
import OneInput from "./OneInput";
import KABodySidebarThree from "../KABody/KABodySidebarThree";

function SidebarThreeBody({
  responseData,
  handeLinkClick,
  handRatingTake,
  isLoading,
  handleGenerateAnswer,
  inputQuery,
  setInputQuery,
}) {
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);

  if (!isRagCollapsed) {
    return (
      <>
        <KABodySidebarThree
          responseData={responseData}
          handeLinkClick={handeLinkClick}
          handRatingTake={handRatingTake}
          isLoading={isLoading}
          inputQuery={inputQuery}
        />
        <div className="kaSidebarThreeBottomChat">
          <OneInput
            handleGenerateAnswer={handleGenerateAnswer}
            inputQuery={isLoading ? "" : inputQuery}
            setInputQuery={setInputQuery}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  }

  return <></>;
}

export default SidebarThreeBody;
