import React from "react";
import { useSelector } from "react-redux";

function SidebarOneFAQ({ handleGenerateAnswer, responseDataLength }) {
  const questions = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData?.questions
  );

  if (questions?.length && responseDataLength === 0) {
    return (
      <div className="kaFaqSidebarOne">
        {questions?.map((data, index) => {
          return (
            <div
              className="kaFaqSidebarOnePanel"
              key={index}
              onClick={() => {
                setTimeout(() => {
                  handleGenerateAnswer(data);
                }, 200);
              }}
            >
              {data}
            </div>
          );
        })}
      </div>
    );
  }

  return <></>;
}

export default SidebarOneFAQ;
