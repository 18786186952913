import React, { useCallback, useState } from "react";
import Input from "../../../../../utils/components/Input";
import { useSelector } from "react-redux";
import Button from "../../../../../utils/components/Button";

function OneInput({
  handleGenerateAnswer,
  inputQuery,
  setInputQuery,
  isLoading,
}) {
  const [errors, setErrors] = useState({});
  const { enterQuestion, generateText } = useSelector(
    (state) => state.language.data
  );

  const handleGenerate = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      const newErrors = {};

      if (!inputQuery.trim()) {
        newErrors.inputQuery = "Please enter a question.";
      }

      setErrors(newErrors);

      if (Object.keys(newErrors)?.length === 0) {
        handleGenerateAnswer();
      }
    },
    [inputQuery, handleGenerateAnswer]
  );

  return (
    <form onSubmit={handleGenerate} className="kaInputDivOne">
      <Input
        placeholder={enterQuestion}
        value={inputQuery}
        onChange={(e) => {
          if (isLoading) {
            return;
          }
          setInputQuery(e.target.value);
          if (errors.inputQuery) {
            setErrors({ ...errors, inputQuery: undefined });
          }
        }}
        error={errors.inputQuery}
      />

      <Button isLoading={isLoading} onClick={handleGenerate}>
        {generateText}
      </Button>
    </form>
  );
}

export default OneInput;
