import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const loadingStatus = [
  "Generating the query",
  "Verifying the query",
  "Querying the database",
  "Creating insights",
];

function DmgLogoTitle({ isLoading }) {
  const [statusIndex, setStatusIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(240); // Timer starts from 240 seconds (4 minutes)
  const logo = useSelector(
    (state) => state.flipcard.data.flipCard.publisher.logo
  );
  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);
  const poweredByBridged = useSelector(
    (state) => state.flipcard.data.config?.poweredByBridged
  );
  const poweredBy_TEXT = useSelector(
    (state) => state.language.data.poweredBy_TEXT
  );
  const engagementTitle = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.title
  );

  useEffect(() => {
    let interval;
    let timerInterval;

    if (isLoading) {
      setStatusIndex(0);
      setTimeLeft(240); // Reset the timer to 4 minutes when loading starts
      interval = setInterval(() => {
        setStatusIndex((prevIndex) =>
          prevIndex < loadingStatus.length - 1 ? prevIndex + 1 : prevIndex
        );
      }, 60000); // Change interval to 1 minute (60000 milliseconds)

      timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > -1 ? prevTime - 1 : prevTime));
      }, 1000); // Timer updates every second
    }

    return () => {
      if (interval) clearInterval(interval);
      if (timerInterval) clearInterval(timerInterval);
    };
  }, [isLoading]);

  const formatTime = (time) => {
    const minutes = Math.floor(Math.abs(time) / 60);
    const seconds = Math.abs(time) % 60;
    return `${time < 0 ? "-" : ""}${minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };

  return (
    <div className="DmgLogoTitle">
      {!logo && !poweredByBridged ? (
        <></>
      ) : (
        <div>
          {!logo && <p>{poweredBy_TEXT}</p>}
          <img
            onClick={() => {
              if (!logo) {
                window.open("https://bridged.media/", "_blank");
              }
            }}
            src={logo || bridgedLogo}
            alt="Logo"
          />
        </div>
      )}

      <h4>{engagementTitle}</h4>

      <h5 style={{ opacity: isLoading ? 1 : 0 }}>
        {loadingStatus[statusIndex]} {formatTime(timeLeft)}
      </h5>
    </div>
  );
}

export default DmgLogoTitle;
